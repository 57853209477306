import "core-js/modules/es.array.push.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "register"
};
const _hoisted_2 = {
  class: "register-title"
};
const _hoisted_3 = {
  class: "register-label"
};
const _hoisted_4 = {
  class: "register-label"
};
const _hoisted_5 = {
  class: "register-label"
};
const _hoisted_6 = {
  class: "register-label"
};
const _hoisted_7 = {
  class: "login-foot__text acea-row row-center-wrapper"
};
import { useIntervalFn } from '@vueuse/core';
import { register, sendEmailCode } from '@/http';
import { router } from '@/router';
import { ref } from 'vue';
import { showToast } from 'vant';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'register',
  emits: ['returnLogin'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const {
      t
    } = useI18n();
    const formData = ref({
      email: '',
      invite_code: '',
      password: '',
      pay_password: '',
      code: ''
    });
    const loading = ref(false);
    const returnLogin = () => {
      emit('returnLogin', false);
    };
    const back = () => {
      router.go(-1);
    };
    const onSubmit = () => {
      loading.value = true;
      register(formData.value).then(({
        code,
        msg
      }) => {
        loading.value = false;
        showToast(msg);
        if (code === 200) {
          router.push('/login');
        }
      }).catch(() => {
        loading.value = false;
      });
    };
    const _sendEmailCode = () => {
      if (isActive.value) return;
      if (!formData.value.email) {
        return showToast(t('please_enter_email'));
      }
      sendEmailCode({
        email: formData.value.email
      }).then(({
        code,
        msg
      }) => {
        showToast(msg);
        if (code === 200) {
          resume();
        }
      });
    };
    const count = ref(60);
    const {
      resume,
      pause,
      isActive
    } = useIntervalFn(() => {
      if (count.value === 0) {
        count.value = 60;
        pause();
      } else {
        count.value--;
      }
    }, 1000, {
      immediate: false
    });
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_el_link = _resolveComponent("el-link");
      const _component_van_button = _resolveComponent("van-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('create_account')), 1), _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('email')), 1), _createVNode(_component_van_field, {
        border: false,
        class: "register-input",
        placeholder: _ctx.$t('input_email_account'),
        modelValue: formData.value.email,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formData.value.email = $event)
      }, null, 8, ["placeholder", "modelValue"]), _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('verification_code')), 1), _createVNode(_component_van_field, {
        border: false,
        class: "register-input",
        placeholder: _ctx.$t('enter_verification_code'),
        modelValue: formData.value.code,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formData.value.code = $event)
      }, {
        button: _withCtx(() => [_createElementVNode("div", {
          style: {
            "color": "#56F9AA"
          },
          onClick: _sendEmailCode
        }, _toDisplayString(_unref(isActive) ? count.value + 's' : _ctx.$t('get_verification_code')), 1)]),
        _: 1
      }, 8, ["placeholder", "modelValue"]), _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('login_password')), 1), _createVNode(_component_van_field, {
        border: false,
        class: "register-input",
        type: "password",
        placeholder: _ctx.$t('set_login_password_input_8_plus_characters'),
        modelValue: formData.value.password,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formData.value.password = $event)
      }, null, 8, ["placeholder", "modelValue"]), _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('funds_password')), 1), _createVNode(_component_van_field, {
        border: false,
        class: "register-input",
        maxlength: 6,
        type: "password",
        placeholder: _ctx.$t('set_funds_password_6_digit_number'),
        modelValue: formData.value.pay_password,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formData.value.pay_password = $event)
      }, null, 8, ["placeholder", "modelValue"]), _createVNode(_component_van_field, {
        border: false,
        class: "register-input",
        placeholder: _ctx.$t('invitation_code'),
        modelValue: formData.value.invite_code,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => formData.value.invite_code = $event)
      }, null, 8, ["placeholder", "modelValue"]), _createElementVNode("div", _hoisted_7, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('createAcount.have')) + "？", 1), _createVNode(_component_el_link, {
        type: "primary",
        onClick: returnLogin
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('createAcount.goLogin')), 1)]),
        _: 1
      })]), _createVNode(_component_van_button, {
        color: "#56F9AA",
        style: {
          "color": "#333"
        },
        round: "",
        block: "",
        onClick: _cache[5] || (_cache[5] = $event => onSubmit()),
        loading: loading.value
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('register')), 1)]),
        _: 1
      }, 8, ["loading"])]);
    };
  }
};